import React from 'react';

class HeaderClass extends React.Component {
    renderHeader() {
      return (
        <div>
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-98336830-2"></script>
          <meta charset="utf-8"/>	
          <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no"></meta>
          <meta name="description" content=""></meta>
          <meta name="author" content=""></meta>
          <title>Naijacaption</title>
          <link href="vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet"></link>
          <link href="css/heroic-features.css" rel="stylesheet"></link>
        </div>
      );
    }

    render() {
        return (
          <head>
            this.renderHeader()
          </head>
          
        );
      }
}

export default HeaderClass;