import logo from './logo.svg';
import './App.css';
import React from 'react';
import './index.css';

import HeaderClass from './Header';
import MainRootClass from './Body';


function App() {
  return (
    
      <MainRootClass />
    
      
  );
}


export default App;
