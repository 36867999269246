import { getSuggestedQuery } from '@testing-library/react';
import React from 'react';
import newsdata from './news2022.json'


class MainRootClass extends React.Component {
    renderNavBar() {
      return (
        <>
          <nav className="navbar navbar-expand-sm bg-info navbar-dark">
            <div className="container-fluid">
              <div className="navbar-header">
                <a className="navbar-brand" href="https://www.naijacaption.com">Naijacaption</a>
              </div>
            </div>
          </nav>
        </>
        
      );
    }

    render() {
        return (
            <>
                {this.renderNavBar()}
                <GridClass />
            </>
        );
      }
}

class GridClass extends React.Component {

  constructor(props) {
    super(props);
  }
  
  iterateNewsEntries(){
    const newsItems = newsdata.map((news_row, current_index) => <SquareClass paper_name={news_row['paper_name']} headline={news_row['headline']}
    headline_link={news_row['headline_link']}  image_link={news_row['image_link']} index={current_index}> </SquareClass>);

    return newsItems    
  }

  splitIntoArrays(){

    var newsEntries = this.iterateNewsEntries()
    var row1 = new Map()
    var rowsArray = []
    let i = 0

    for (let entry in newsEntries ){

      if ((i % 3) == 0){        
        row1.set("square1", newsEntries[entry])
      }
      else if (i % 3 == 1){
        row1.set("square2", newsEntries[entry] )
      }
      else if (i % 3 == 2){
        row1.set("square3", newsEntries[entry] )
      }

      if ( (row1.size == 3) || (i == (newsEntries.length - 1))){
        rowsArray.push(row1)
        row1 = new Map()
      }

      i++            
    }
    

    var rows = []

    for (let i in rowsArray){
      var row = <RowClass square1={rowsArray[i].get('square1')} square2={rowsArray[i].get('square2')} square3={rowsArray[i].get('square3')} key={i}>  </RowClass>
    
      rows.push(row)
    }

    return rows;
  }

  render() {
      return (
        <>
          <div className="container-fluid">
            {this.splitIntoArrays()}
          </div>          
        </>
      );
    }
}


class RowClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      squares: {square1: this.props.square1,
                square2: this.props.square2,
                square3: this.props.square3
              } 
      };
  }
  renderRow() {
    return (
      <>
      <div className="container-fluid">
        <div className="row justify-content-center">
          {this.state.squares.square1}
          {this.state.squares.square2}
          {this.state.squares.square3}
        </div>
      </div> 
      </>
    );
  }

  render() {
    return (
      <div>
        {this.renderRow()}
      </div>
    );
  }

}


class SquareClass extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      details: {newspaper: this.props.paper_name,
                  headline: this.props.headline,
                  image_link: this.props.image_link,
                  headline_link: this.props.headline_link} 
      };
  }

  renderSquare() {
    var fullHeadline = "@" + this.state.details.newspaper + " " +  this.state.details.headline
    return (
      <>      
        <button className="btn-light-whole-square col-lg-12 col-xl-3 " >          
          <a href={this.state.details.headline_link} class="btn-light-image-and-link" target="_blank">
            <img className="img-fluid" src={this.state.details.image_link} alt={this.state.details.newspaper}></img>
            <a href={this.state.details.headline_link} class="btn-headline-text"  target="_blank">{fullHeadline}</a>
          </a>
        </button>
        
      </>
    );
  }

  render(){
    return (
      <>
        {this.renderSquare()}
      </>
    )
  }
}



export default MainRootClass;